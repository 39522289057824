const getDataLS = (key: string, dflt: any = null) => {
  try {
    const dataLS = localStorage.getItem(key)
    return dataLS ? JSON.parse(dataLS) : dflt
  } catch(e) {
    console.error(e)
    return dflt
  }
}

export const getPaymentsRestrictionsLS = localStorage.getItem('payments_restrictions')

export const setPaymentsRestrictionsLS = (value: string) => localStorage.setItem('payments_restrictions', value)

export const setUtmLs = (value: string) => localStorage.setItem('utm', value)
export const getUtmLs = () => localStorage.getItem('utm')

interface BannersLs {
  preNewYearSaleBanner?: {
    closed?: boolean
  }
}

export const setBannersLs = (value: BannersLs) => localStorage.setItem('banners', JSON.stringify(value))
export const getBannersLs = (): BannersLs => getDataLS('banners', {})
