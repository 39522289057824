import React from 'react';
import styled, { css } from 'styled-components';
import { IMap, ObjectType, getAspectsPairs, PairAspects, CircleMode, IAspect, getSign, PrognosticsMode, isNotTransits, AspectType } from 'src/libs';
import { aspectsIcons, objectsIcons } from 'src/icons';
import { IWidgetData } from '../Widget';
import Icon from './Icon';
import { IHighlight } from 'src/pages/Instruments';
import { IPoint } from 'src/api';
import { getDeviceDetect } from 'src/utils';
import store from 'src/store';
import { isCompatibility } from '../../utils';
import { TWidgetCircleMode } from './ModeSelectorTabs';
import { ECompatibilityTransitMode } from 'src/store/InstrumentsStore';

import { isPrognostics, axesObjects, getCellIndex, cellClassName, headerIcon, hasExt, highlightAspect, extractMode } from './utils';


const { isTablet } = getDeviceDetect();



interface IAspectsTableCell {
  cell: React.RefObject<HTMLTableDataCellElement>;
  icon: React.RefObject<Icon>;
  aspectId: number;
  weight: number;
}

interface AspectsTableProps {
  data: IWidgetData;
  doubleMapNatal?: boolean;
  onChanged(key: string, value: any): void;
}
export default class AspectsTable extends React.Component<AspectsTableProps, {isPrognostics: boolean}> {
  private readonly _ref = React.createRef<HTMLTableElement>();
  private readonly _cells: IAspectsTableCell[][] = [];
  private _prevHover: IPoint | null = null;

  constructor(props: AspectsTableProps) {
    super(props);

    this.state = {
      isPrognostics: false,
    };

    for (let i = 0; i < 21; i++) {
      const row: IAspectsTableCell[] = [];

      for (let j = 0; j < 21; j++) {
        row.push({
          cell: React.createRef<HTMLTableDataCellElement>(),
          icon: React.createRef<Icon>(),
          weight: 0,
          aspectId: -1
        });
      }

      this._cells.push(row);
    }
  }

  async componentDidMount() {
    this.update(this.props);

    const mode = this.props.data.widgetMode;
    this.setState({
      isPrognostics: isPrognostics(mode)
    });
  }

  componentWillUnmount() {
    this.props.onChanged('pinned-aspects', []);
  }

  shouldComponentUpdate(newProps: AspectsTableProps, newState: any) {
    if (newProps.data.form.cosmogram !== this.props.data.form.cosmogram) return true;
    this.update(newProps);

    if ((newProps.data.doubleMapNatal as any)[newProps.data.widgetMode] !== (this.props.data.doubleMapNatal as any)[this.props.data.widgetMode]) return true;

    if (newProps.data.widgetMode !== this.props.data.widgetMode) return true;
    if (newProps.data.widgetMode !== this.props.data.widgetMode) this.onHover(0, 0);

    return false;
  }

  updateCells(props: AspectsTableProps, show: boolean, mode: TWidgetCircleMode) {

    // вычисляем реальный mode, так как в props.data.widgetMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _mode = extractMode(props.data.widgetMode, '-') as CircleMode;
    const map: IMap | undefined = props.data.maps.find(m => m.mode == _mode);

    if (!map) return;

    // включен/отключен натал в двойной карте прогностики
    const indicatorPrognosticsNatal: boolean = (props.data.doubleMapNatal as any)[map.mode as PrognosticsMode];
    const { transitMode } = store.instruments.mapIndicatorCurrent.compatibilityTransits[_mode] || {};
    const { compatibilityReversed } = store.instruments.mapIndicatorCurrent;
    const hasSynastryTransit: boolean = Boolean(props.data.form?.syn_prognostics);

    let _aspects = map.aspects;

    // если в двойной карте отключен Натал, то скрываем аспекты с минорными домами
    if (!indicatorPrognosticsNatal && ['solars', 'prog_natal', 'transits'].includes(map.mode)) {
      _aspects = _aspects.filter((aspect: IAspect) => {
        const { type, obj1, obj2 } = aspect;
        return (obj1 >= ObjectType.House1 ? axesObjects.includes(obj1) : true)
      })
    }

    // если widgetMode == совместимость и включены транзиты для этой совместимости и режим транзита = 1
    //, то уберем из аспектов транзитные, что бы показывать их уже в отдельном табе
    if (hasSynastryTransit && transitMode === ECompatibilityTransitMode.MODE_1) {
      
      const { transitPartnerAspects = [], transitNatalAspects = [] } = map || {};
      const transitAspects = (transitPartnerAspects).concat(transitNatalAspects);

      // const housesAspects = transitAspects.filter((aspect: IAspect) => (aspect.obj1 >= ObjectType.House1) || (aspect.obj2 >= ObjectType.House2))
      
      
      if (isCompatibility(props.data.widgetMode as CircleMode)) {
        _aspects = _aspects.filter((aspect: IAspect) => {
        
          return !transitAspects.some((tAspect: IAspect) => {
            return (
              tAspect.diff === aspect.diff
              && tAspect.lon1 === aspect.lon1
              && tAspect.lon2 === aspect.lon2
              && tAspect.obj1 === aspect.obj1 
              && tAspect.obj2 === aspect.obj2 
              && tAspect.type === aspect.type
            )
          });
        })
      }


      else if (props.data.widgetMode.includes('transit-natal')) {
        _aspects = (!compatibilityReversed ? map.transitNatalAspects : map?.transitPartnerAspects) || []
      }
    
      else if(props.data.widgetMode.includes('transit-partner')) {
        _aspects = (!compatibilityReversed ? map.transitPartnerAspects : map?.transitNatalAspects) || []
      }

      // если это совместимость и транзитный режим === 2
    } else if (hasSynastryTransit && transitMode === ECompatibilityTransitMode.MODE_2) {
      // _aspects = _aspects.filter((aspect: IAspect) => aspect.type === AspectType.Conjunction)
      // _aspects = [...(map.transitNatalAspects || []), ...(map.transitPartnerAspects || [])]

    }

    const asps = new Set<number>();

    const highlightsData = Array.isArray(props.data.highlights) ? props.data.highlights : props.data.highlights.items;
     
    const highlights = show ? highlightsData
      .filter(h => h.map == props.data.widgetMode && h.type == 'aspect')
      .map(h => h.id) : [];

    if (show && this._prevHover && highlights.length == 0) {
      const { x, y } = this._prevHover;
      this.highlight(y, x);
    }

    _aspects.forEach(asp => {
      const col = getCellIndex(asp.obj1)!;
      const row = getCellIndex(asp.obj2)!;
      if (!col || !row) {
        console.log('fail asp', asp);
        return;
      }
      
      const w = row.w + col.w;

      let cell

      if (mode === 'natal' || mode === 'syn_natal' || mode.startsWith('partner')) {
        if (asp.obj2 >= ObjectType.House1) {
          cell = this._cells[row.id][col.id]

        } else {
          cell = this._cells[col.id][row.id]
        }
      } else {
        cell = this._cells[col.id][row.id];
      }

      if (!cell || (show && w < cell.weight)) { return }

      if(cell.icon.current) {(cell.icon.current as Icon).setIcon(show ? aspectsIcons[asp.type] : null, `var(--circle-aspects-${asp.type})`)};

      cell.aspectId = show ? asp.id : -1;
      cell.weight = show ? w : 0;

      asps.add(asp.id);

      if (props.data.pinnedAspects.includes(asp.id)) {
        cell.cell.current!.classList.toggle('pinned');
      }
    });
  }

  clearCells(props: AspectsTableProps) {
    for (let i = 0; i < 21; i++) {
      for (let j = 0; j < 21; j++) {
        const cell: IAspectsTableCell = this._cells[i][j];
        cell.icon?.current?.setIcon(null, '');
        cell.aspectId = -1;
        cell.cell.current?.classList.remove('pinned');
      }
    }
  }

  update(props: AspectsTableProps) {
    
    // вычисляем реальный mode, так как в props.data.widgetMode могут быть субрежимы типа 'compatibility1-transit-partner'
    const _mode: CircleMode = (props.data.widgetMode?.split('-')?.[0] as CircleMode) ?? props.data.widgetMode;
    const isPrognosticsMode = isPrognostics(_mode);

    const isSynastryTransitsMode = props.data.widgetMode.startsWith('compatibility') && props.data.widgetMode.includes('-transit-')
    const isSynastryTransits = props.data.form?.syn_prognostics;
    const { transitMode } = store.instruments.mapIndicatorCurrent.compatibilityTransits[_mode] ?? {};

    const indicatorPrognosticsNatal = (props.data.doubleMapNatal as any)[_mode as PrognosticsMode];

    const ref = this._ref.current as HTMLTableElement;

    console.log(`AspectTable update for mode: ${_mode} aspects - `, props.data.maps[0].aspects)

    ref.setAttribute('data-small', isPrognosticsMode.toString());

    ref.setAttribute('data-show-chiron', (_mode == 'transits').toString());

    if (
        isPrognosticsMode 
        || _mode.startsWith('relocation') 
        || ['natal','syn_natal'].includes(_mode) 
        || _mode.startsWith('partner')
        || (isSynastryTransits && isSynastryTransitsMode && transitMode === ECompatibilityTransitMode.MODE_1)
      ) {
      ref.setAttribute('data-show-h-axis', 'true');
    } else {
      ref.setAttribute('data-show-h-axis', 'false');
    }

    ref.setAttribute('data-show-v-axis', (isPrognosticsMode && isNotTransits(_mode)).toString());

    if (_mode === 'prog_natal' && !indicatorPrognosticsNatal) {
      ref.setAttribute('data-show-h-axis', 'false');
      ref.setAttribute('data-show-v-axis', 'false');
    }

    if (_mode === 'solars' && !indicatorPrognosticsNatal) {
      ref.setAttribute('data-show-v-axis', 'false');
    }

    if (!(_mode == 'transits' || isPrognosticsMode)) {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.add('withoutRightBorder') }
      });
    } else {
      ref.querySelectorAll('tr td:not(.chiron):not(.v-axis)').forEach((item, i) => {
        if ((i + 1) % 14 === 0) { item.classList.remove('withoutRightBorder') }
      });
    }

    this.updateCells(props, false, _mode);

    this.clearCells(props);

    this.updateCells(props, true, _mode);

    this.hideAspect(ref, _mode)

  }

  highlight(row: number, col: number) {
    const cell = this._cells[row][col];

    // if (!cell.icon.current?.sv)

    const fill = (row: number, col: number) => {

      for (let i = 0; i < col; i++) {
        this._cells[row][i].cell.current!.classList.toggle('h-line');
        if (this._cells[row - 1]?.[i]?.cell?.current) { this._cells[row - 1][i].cell.current!.classList.toggle('h-line-prev') }
      }

      for (let i = 0; i < row; i++) {
        if (this._cells[i]?.[col - 1]?.cell?.current) { this._cells[i][col - 1].cell.current!.classList.toggle('v-line-prev') }
        this._cells[i][col].cell.current!.classList.toggle('v-line');
      }

      this._cells[row][0].cell.current!.classList.toggle('h-first');
      this._cells[0][col].cell.current!.classList.toggle('v-first');

      this._cells[row][col].cell.current!.classList.toggle('current');
    };

    if (this._prevHover) {
      fill(this._prevHover.y, this._prevHover.x);
    }

    if (cell.weight) {
      this._prevHover = {
        x: col,
        y: row
      };

      fill(row, col);
    } else {
      this._prevHover = null;
    }
  }

  onHover(row: number, col: number, evt?: any) {
    // if (this._prevHover && this._prevHover.x == col && this._prevHover.y == row) return;

    evt?.stopPropagation();
    
    const _mode = extractMode(this.props.data.widgetMode, '-') as CircleMode;
    const indicatorPrognosticsNatal = (store.instruments.mapIndicatorCurrent.prognosticsNatal as any)[_mode as PrognosticsMode];
    const indicatorPartnerNatal = (store.instruments.mapIndicatorCurrent.partnerNatal as any)[_mode as PrognosticsMode];
    const { compatibilityTransits, compatibilityReversed } = store.instruments.mapIndicatorCurrent;

    const isHasSynastryPrognostics = this.props.data.form?.syn_prognostics;

    const cell = this._cells[row][col];

    cell.cell.current?.querySelector('svg')

    if (!this._prevHover
      && cell.aspectId == -1
      && row !== 0
      && col !== 0) { return }

      // Если это совместимость и транзитный режим === 2, то не подсвечиваем
    if (isHasSynastryPrognostics && compatibilityTransits?.[_mode]?.showTransit
        && !this.props.data.widgetMode.includes('transit-natal') 
        && !this.props.data.widgetMode.includes('transit-partner')
      ) {
      return;
    }
    
    const currentMap = this.props.data.maps.find(m => m.mode == _mode);
    const aspect: IAspect = currentMap?.aspects[cell.aspectId]!;

    // аспект из массива аспектов натал - транзит
    const isSynTransitNatalAspect = currentMap?.[(true || !compatibilityReversed) ? 'transitNatalAspects' : 'transitPartnerAspects']?.some(a => a.id == aspect?.id) || false;
    // аспект из массива аспектов партнер - транзит
    const isSynTransitPartnerAspect = currentMap?.[true || !compatibilityReversed ? 'transitPartnerAspects' : 'transitNatalAspects']?.some(a => a.id == aspect?.id) || false;
    
    const isHasOuter = isSynTransitNatalAspect || isSynTransitPartnerAspect;
    
    const isHasExt = !isHasOuter ? (indicatorPrognosticsNatal || indicatorPartnerNatal) : hasExt(_mode)
      && isHasOuter ? (isSynTransitPartnerAspect && !isSynTransitNatalAspect) : hasExt(_mode)
      
    const highlightItems: IHighlight[] = highlightAspect(
      _mode,
      aspect,
      isHasExt,
      isHasOuter,
      compatibilityReversed
    )
  
    this.props.onChanged(
      'highlights',
      cell.aspectId == -1 
        ? [] 
        : { items: highlightItems }
    );
    
    this.highlight(row, col);
  }

  onClick(ev: any, y: number, x: number) {
    const isHasSynastryPrognostics = this.props.data.form?.syn_prognostics;
    const _mode = extractMode(this.props.data.widgetMode, '-') as CircleMode;

    if (isHasSynastryPrognostics && store.instruments.mapIndicatorCurrent.compatibilityTransits[_mode]?.showTransit
      && !this.props.data.widgetMode.includes('transit-natal') 
      && !this.props.data.widgetMode.includes('transit-partner')
    ) {
    return;
    }

    const { aspectId } = this._cells[y][x];

    let pinned: number[] = [];
    const { pinnedAspects } = this.props.data;

    if (aspectId != -1) {
      const includes = pinnedAspects.includes(aspectId);
      if (ev.ctrlKey || ev.metaKey) {
        pinned = includes ? pinnedAspects.filter(id => id != aspectId) : [...pinnedAspects, aspectId];
      } else {
        pinned = includes ? [] : [aspectId];
      }
    }

    this.props.onChanged('pinned-aspects', pinned);
  }

  hideAspect(ref: HTMLTableElement, mode: TWidgetCircleMode) {
    const rowLilith = ref.querySelector('td.lilith.row_header')?.parentElement;
    const rowChiron = ref.querySelector('td.chiron.row_header')?.parentElement;
    const rowSouthNode = ref.querySelector('td.south-node.row_header')?.parentElement;

    const indicatorPrognosticsNatal = (store.instruments.mapIndicatorCurrent.prognosticsNatal as any)[mode as PrognosticsMode];
    const activeProfileStore = store.settings.profile.activeProfileId
    
    if (mode == 'transits' && indicatorPrognosticsNatal && activeProfileStore === 0) {
      rowLilith?.classList.add('not-show');
      rowChiron?.classList.add('not-show');
      // rowSouthNode?.classList.add('not-show');
    } else {
      rowLilith?.classList.remove('not-show');
      rowChiron?.classList.remove('not-show');
      rowSouthNode?.classList.remove('not-show');
    }
  }

  render() {
    const rows = [];
    const isTransits = this.props.data.widgetMode === 'transits'
    const isActiveProfilePA = store.settings.profile.activeProfileId === 0; // Школа ПА
    const isHasSynastryTransits = this.props.data.form.syn_prognostics;
    const headers: any[] = [<Cell key="corner" className="v-header" ref={this._cells[0][0].cell} isTransits={isTransits}><Icon dot /></Cell>];

    const isCompatibility = this.props.data.widgetMode.startsWith('compatibility') // добавленна для автотестов в data-td-display
    const hidedModes = ['natal', 'partner', 'relocation_natal', 'compatibility'].some(mode => this.props.data.widgetMode.startsWith(mode)); // добавленна для автотестов в data-td-display
    const prognosticModes = ['solars','directions','prog_natal'].some(mode => this.props.data.widgetMode.startsWith(mode)) // добавленна для автотестов в data-td-display
    
    for (let i = 0; i < 20; i++) {
      headers.push(
        <Cell
          key={`col_header_${i}`}
          className={`v-header ${cellClassName(i, 'v')}`}
          ref={this._cells[0][i + 1].cell}
          isTransits={isTransits}
        >
          {headerIcon(i)}
        </Cell>
      );
    }
    rows.push(<Row key={'col_headers'}>{headers}</Row>);
    const cosmogramNatalmode = this.props.data.form?.cosmogram && this.props.data.modes.includes('natal')
    const quantityOfCells = this.props.data.form?.cosmogram && this.props.data.modes.includes('transits') || cosmogramNatalmode ? 14 : 20;

    for (let i = 0; i < quantityOfCells; i++) {
      const hClassName = cellClassName(i, 'h');

      const chironOrLilith = i === ObjectType.Chiron  || i === ObjectType.Lilith
      const hideRowMark = ( // добавленна для автотестов в data-tr-display
        !isTransits && i === ObjectType.Chiron 
        || isTransits && isActiveProfilePA && chironOrLilith
        || isCompatibility && i >= ObjectType.Chiron
      )
      const cols: any[] = [
        <Cell
          key={`row_header_${i}`}
          ref={this._cells[i + 1][0].cell}
          className={`${hClassName} row_header`}
          isTransits={isTransits}
        >
          {headerIcon(i)}
        </Cell>
      ];

      for (let j = 0; j < quantityOfCells; j++) {
        cols.push(
          <Cell
            key={`cell_${j}`}
            onMouseEnter={(evt: any) => this.onHover(i + 1, j + 1, evt)}
            onMouseLeave={() => this.onHover(0, 0)}
            onClick={ev => this.onClick(ev, i + 1, j + 1)}
            ref={this._cells[i + 1][j + 1].cell}
            className={`${cellClassName(j, 'v')} ${hClassName}`}
            data-td-display={`${
              (hidedModes && j >= ObjectType.Chiron) ||
              (isTransits && j > ObjectType.Chiron) ||
              (prognosticModes && j === ObjectType.Chiron) ? 'none' : '' }`}
            isTransits={isTransits}
          >
            <Icon ref={this._cells[i + 1][j + 1].icon} />
          </Cell>
        );
      }
      rows.push(<Row key={`row_${i}`} data-tr-display={`${hideRowMark ? 'none' : ''}`}>{cols}</Row>); 
    }

    return (
        <Table ref={this._ref} isTablet={isTablet} isPrognostic={isPrognostics(this.props.data.widgetMode)}>
          <tbody>
            {rows}
          </tbody>
        </Table>
    );
  }
}

const Table = styled.table<{ isTablet?: boolean; isPrognostic?: boolean}>`
  display: block;
  position: relative;
  border-collapse: collapse;
  box-sizing: border-box;
  margin: 0;
  scroll-margin: 3.5rem 0 0 0;

  > tbody {
    display: flex;
    flex-direction: column;

    > tr {
      display: flex;
    }
  }

  /* &[data-small=true] {
    td {
      width: 100%;
      height: 100%;
    }
  } */

  &[data-show-h-axis=false] {
    td.h-axis {
      display: none;
    }
  }

  &[data-show-v-axis=false] {
    td.v-axis {
      display: none;
    }
  }

  &[data-show-chiron=false] {
    td.chiron {
      display: none;
    }
  }

  .not-show {
    display: none;
  }
`;

const Row = styled.tr`
  display: flex;

  :first-child td {
    border-top: 1px solid var(--aspects-table-border);
    border-bottom: 1px solid var(--aspects-table-border-light);
  }

  & > td:first-child {
    border-right: 1px solid var(--aspects-table-border-light);
  }
`;

const Cell = styled.td<{isTransits: boolean}>`
  display: flex;
  padding: 0;
  flex: 1;
  border-right: 1px solid var(--aspects-table-border);
  border-bottom: 1px solid var(--aspects-table-border);

  aspect-ratio: 1 / 1;

  @supports not (aspect-ratio: 1 / 1) {

    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }

  }

  &.pinned {
    background: var(--aspects-table-background-hover);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      float: none;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding-top: 0;
      transform: translate(-1px, -1px);
      border: 1px solid var(--aspects-table-border-light);
      border-radius: 1px;
    }
  }


  &.current {
    background: var(--aspects-table-background-current);
    position: relative;

    &:before{
      display: block;
      content: '';
      position: absolute;
      float: none;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding-top: 0;
      transform: translate(-2px, -2px);
      border: 2px solid #EF5350;
      border-radius: 4px;
    }
    /* border: 1px solid #EF5350; */
  }

  &.h-line {
    background: var(--aspects-table-background-hover);
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-line-prev {
    border-bottom-color: var(--aspects-table-border-light);
  }
  &.h-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.v-line {
    background: var(--aspects-table-background-hover);
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-line-prev {
    border-right-color: var(--aspects-table-border-light);
  }
  &.v-first {
    position: relative;
    &:after{
      display: block;
      content: '';
      top: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--aspects-table-border-light);
    }
  }

  &.withoutRightBorder {
    border-right: none;
  }

`;
